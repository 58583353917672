import { useState } from "preact/hooks";
import SkuTile from "@/components/SkuTile";

const SkuTileGrid = ({ message, handleButtonClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  console.log("Loading SkuTileGrid");
  console.log(message);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-1">
        {message.replyOptions.map((option) => (
          <SkuTile
            key={option.selectionIntent}
            replyOption={option}
            onButtonClick={handleButtonClick}
          />
        ))}
      </div>
      {message.extendedProperties && (
        <div>
          <a href="#" onClick={handleModalOpen}>
            {message.extendedProperties.guarantee}
          </a>
          <br />
          {message.extendedProperties.cancel_anytime}
        </div>
      )}
      {isModalOpen && (
        <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="modal-content bg-white text-black p-4 text-lg rounded mx-6"
            style={{ maxWidth: "26rem" }}
          >
            <p>{message.extendedProperties.guarantee_expand}</p>
            <button className="text-white bg-blue-500 mt-3" onClick={handleModalClose}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SkuTileGrid;
