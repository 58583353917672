import { IS_PROD } from "@/lib/global_imports";

const FORCE_PROD = false;
const WORKER_PROD = "https://api.taalia.com/v1/chat";
const WORKER_DEV = "http://localhost:8910/v1/chat";

export async function postMessage(message, queryParams, waitForResponse = true) {
  try {
    console.log("Posting message:", message);

    // Construct the URL with query parameters
    const url = buildApiUrl(queryParams);

    // Create the body of the request
    const body = JSON.stringify(message ? message : {});

    // Create the headers
    const headers = {
      "Content-Type": "application/json",
      "Installation-Id": "web",
      "App-Id": "53@5sd+7f7",
    };

    // Create the request object
    const request = {
      method: "POST",
      body,
      headers,
      credentials: "include", // Ensure cookies are included in the request
    };
    // console.log("Request:", JSON.stringify(request, null, 2));

    if (waitForResponse) {
      // Send the request to the server
      const response = await fetch(url, request);

      // Convert the response to JSON
      const data = await response.json();
      console.log("Chat Server Response:", data);

      // Return the response
      return data;
    } else {
      fetch(url, request);
    }
  } catch (error) {
    console.error(error);
    return "Error generating response from the server.";
  }
}

function buildApiUrl(queryParams) {
  // Determine the worker URL based on the environment
  const apiUrl = FORCE_PROD || IS_PROD ? WORKER_PROD : WORKER_DEV;
  console.log("Chat API URL:", apiUrl);

  // Construct the URL
  const url = new URL(apiUrl);

  // Add query parameters to the URL
  if (queryParams) {
    Object.keys(queryParams).forEach((key) =>
      url.searchParams.append(key, queryParams[key])
    );
  }

  // Return the constructed URL
  return url;
}
