import { postMessage } from "@/api/chat";
import { useEffect, useState } from "preact/hooks";

export default function StripeCheckoutHandler() {
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);

        // Post a message to the chat interface to indicate that the payment is successful
        const stripeSessionId = urlParams.get("session_id");
        const cancelled = urlParams.get("cancelled") ?? false;
        const message = {
          cancelled,
          stripeSessionId,
          ixid: {
            messageId: "payment_outcome",
          },
        };
        await postMessage(message, null, true);

        // Redirect to the return URL with the success parameter
        const encodedReturnUrl = urlParams.get("return_url");
        let returnUrl = decodeURIComponent(encodedReturnUrl);
        returnUrl = `${returnUrl}?payment_return_success=${!cancelled}`;
        window.location.href = returnUrl;
      } catch (err) {
        console.error("Error fetching session data:", err);
        setError("There was an error retrieving your checkout details.");
      }
    };

    fetchSessionData();
  }, []);

  if (error) return <div>{error}</div>;
  return <div>...</div>;
}
