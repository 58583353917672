import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState, useEffect, useRef } from "preact/hooks";

export default function MessageInput({ onSubmit }) {
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSend = (e) => {
    e.preventDefault();
    if (input.trim()) {
      console.log("handleSend", input);
      onSubmit(input);
      setInput("");
    }
  };

  return (
    <form onSubmit={handleSend} className="flex w-full space-x-2">
      <Input
        ref={inputRef}
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message here..."
        className="flex-1 text-lg"
      />
      <Button
        type="submit"
        variant="default"
        onMouseDown={handleSend}
        onTouchStart={handleSend}
      >
        Send
      </Button>
    </form>
  );
}
